import React, { useEffect, useState, ChangeEvent } from 'react';
import './md.scss';
import { MenuBar } from '../../components/shared/Menubar/Menubar';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes, Router } from "../../Router";
import { FUllPill } from '../../components/shared/Pill/Pill';
import { AdminService, WLOCreditAPIService } from '../../services';
import { useSignContext } from '../../hooks/useSignContext';
import { useRequestContext } from '../../hooks/useRequestContext';
import { ArrowForward } from '@mui/icons-material';
import ClockLoader from "react-spinners/ClockLoader";
import Fab from '@mui/material/Fab';
import { truncate } from 'fs/promises';
import { parse } from 'path';
import toast from 'react-hot-toast';

interface MensajProps {
}

export function MensajeDespuesdeValidarSargilat(props: MensajProps) {
  const { sign, setSign } = useSignContext();
  const { request, setRequest } = useRequestContext();
  const [texto, setTExto] = useState("");
  const [sargilat, setSargilat] = useState(false);
  const [button, setButton] = useState(false);
  const [button2, setButton2] = useState(false);
  const [estudio, setEstudio] = useState(false);
  const [Negado, setNEgado] = useState(false);
  const [DataCreditoCodeudor, setDataCreditoCodeudor] = useState(false);
  const [solicutde, setSolicutde] = useState(false);
  const [MesanjeInfo, setMensajeInfo] = useState(false);
  const [textoMensaje, setTextoMensaje] = useState("");
  const [codigoClie, setCodigo] = useState("");
  const [valorfalse, setvalorfalset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnyChecked, setIsAnyChecked] = useState(false);
  const [checkboxes, setCheckboxes] = useState({ si: false, no: false });

  let buscar = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add('body-login17');
    return () => {
      document.body.classList.remove('body-login17');
    };
  }, []);
  useEffect(() => {
    if (buscar) {
      var valorBusqueda = buscar.search.replace("?", "")
      var valorBusqueda2 = valorBusqueda.split("-")
      console.log("buenas...")
      if (Number(valorBusqueda2[0]) === 0) {
        setSargilat(false)

        if (Number(request.valoresLogin.tipodeudor) < 2) {
          setButton(false)
        } else {
          setButton(true)
        }
      } else {
        setTExto("SI")
        if (Number(request.valoresLogin.tipodeudor) < 2) {
          setButton(true)
        } else {
          setButton(true)
        }
        setSargilat(true)
      }

      //NEGADO
      if (Number(valorBusqueda2[1]) === 0) {
        setNEgado(false)
      } else {
        if (Number(request.valoresLogin.tipodeudor) < 2) {
          setButton(true)
        } else {
          setButton(true)
        }
        setNEgado(true)
        setTExto("SI")
      }

      //COODEUDOR
      if (Number(valorBusqueda2[2]) === 0) {
        setDataCreditoCodeudor(false)
      } else {
        setDataCreditoCodeudor(true)
      }

      //EN ESTUDIO
      if (Number(valorBusqueda2[3]) === 0) {

        if (Number(request.valoresLogin.tipodeudor) < 2) {
          setEstudio(false)
        }
      } else {
        if (Number(request.valoresLogin.tipodeudor) < 2) {
          setButton(true)
        } else {
          setButton(true)
        }
        setTExto("SI")
        setEstudio(true)
      }
      opcionBotones(Number(valorBusqueda2[0]), Number(valorBusqueda2[2]), Number(valorBusqueda2[1]), Number(valorBusqueda2[3]))
    }
    modalMoto()
  }, [buscar]);
  const opcionBotones = (Sagrilaft: any, CodeudorReq: any, Negado: any, Estudio: any) => {
    setButton2(false)
    if (Sagrilaft || Negado || Estudio) {
      if (Number(request.valoresLogin.tipodeudor) < 2) {
        setButton2(true)
      }
    }
  }

  const MostrarModal = async (info: boolean, nega: any, sargila: any, estudio: any, datacreditoreq: any, check: boolean) => {


    if (nega) {
      setTextoMensaje("NEGADO")
    }
    else if (check == false && datacreditoreq == true) {
      setTextoMensaje("REQUIERE CODEUDORES")

    } else if (check == false && datacreditoreq == false) {
      setTextoMensaje("CODEUDOR PROPIETARIO")

    } else if (sargila || estudio) {
      setTextoMensaje("EN ESTUDIO")


    } else {

      let mensaje = ""
      let mensaje2 = ""
      let MensajeEnvio = ""
      if (datacreditoreq) {
        mensaje2 = "CODEUDOR REQUERIDO"
        MostrarModal(true, false, false, false, true, false)
        setTextoMensaje(mensaje2)
      }

      if (mensaje2) {
        if (!info) {
          MensajeEnvio = mensaje;
          if (mensaje2) MensajeEnvio = MensajeEnvio + "  ";
          MensajeEnvio = MensajeEnvio + mensaje2;
        } else {
          MensajeEnvio = mensaje2;
        }
      } else {
        if (!info) {
          MensajeEnvio = mensaje;
        } else {
          MensajeEnvio = "APROBADO";
        }
      }
      setTextoMensaje(MensajeEnvio)
    }
    if (Number(request.valoresLogin.tipodeudor) < 2) {
      setMensajeInfo(true)
    }
    setTimeout(() => {
      navigate(AppRoutes.SIGN)
    }, 10000);
  }

  const valorChange = async () => {
    if(isChecked1 || isChecked2){
      console.log("Entra a valor change");
      let valorfalse = true
      
      console.log(DataCreditoCodeudor);
      console.log(Negado);
      console.log(estudio);
  
  
  
      if (isChecked2 == true && DataCreditoCodeudor == false && Negado == false && estudio == false) {
  
        let MensajeEnvio
        let valoragregado = {
          Pws_num_solicitud: request.numSol!,
          Pws_fec_solicitud_ini: "",
          Pws_fec_solicitud_fin: "",
          Pws_Tip_estado: "",
          Pws_Tip_Consulta: "1"
        }
  
        const consultaSolicitud = await WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado)
  
        if (consultaSolicitud.payload.data.Pws_R_s_cod == "1") {
          let MensajeEnvio
          const solicitud = consultaSolicitud.payload.data["datos_soli"]["datos_Sol"][0]
          console.log("1")
          const personaRequest = {
            Pws_ident_vende: solicitud["Pws_iden_vende"],
            Pws_nom_oficina: solicitud["Pws_nom_oficina"],
            Pws_num_solicitud: request.numSol!,
            Pws_Identificacion: solicitud["Pws_Identificacion"],
            Pws_Tip_Identificacion: solicitud["Pws_Tip_Identificacion"],
            Pws_Tip_Perfil: solicitud["Pws_Tip_Perfil"],
            Pws_linea_credito: solicitud["Pws_Linea_Credito"],
            Pws_Periodic: solicitud["Pws_Periodicidad"],
            Pws_Tip_person: solicitud["Pws_Tip_Person"],
            Pws_Fideliza: solicitud["Pws_Fideliza"],
            Pws_Tip_Estado: "8",
            Pws_fec_solicitud: solicitud["Pws_fec_solicitud"],
            Pws_Nombres: solicitud["Pws_Nombres"],
            Pws_Apellidos1: solicitud["Pws_Apellidos1"],
            Pws_Apellidos2: solicitud["Pws_Apellidos2"],
            Pws_Telefono1: solicitud["Pws_Telefono1"],
            Pws_Telefono2: solicitud["Pws_Telefono2"],
            Pws_Correo: solicitud["Pws_Correo"],
            Pws_Val_cuoini: solicitud["Pws_Val_cuoini"],
            Pws_Val_pla: solicitud["Pws_Val_pla"],
            Pws_Val_finan: solicitud["Pws_Val_finan"],
            Pws_Val_cuo: solicitud["Pws_Val_cuo"],
            Pws_Cod_prod: solicitud["Pws_Cod_prod"]
          }
          console.log(personaRequest);
          const actualizarEstado = await WLOCreditAPIService.addSolicitud(personaRequest)
          if (actualizarEstado.payload.result > 0) {
            const correo = {
              id: "",
              cupo: "0",
              number: request.numSol!,
              deseasMoto: true,
              email: sign.email!,
              typeIdent: sign.typeIdentification!,
              numbIdent: sign.numIdentification!,
              codeudorReq: false,
              Negado: false,
              sargilaft: false,
              enestudio: false
            }
            const response = await WLOCreditAPIService.getEmailCo(correo);
            MostrarModal(true, Negado, sargilat, estudio, false, false)
            MensajeEnvio = 'CODEUDOR PROPIETARIO'
            setTextoMensaje(MensajeEnvio);
          }
        }
      } else if (isChecked2 == true && DataCreditoCodeudor == true && (Negado == false || estudio == false)) {
        let valoragregado = {
          Pws_num_solicitud: request.numSol!,
          Pws_fec_solicitud_ini: "",
          Pws_fec_solicitud_fin: "",
          Pws_Tip_estado: "",
          Pws_Tip_Consulta: "1"
        }
        let MensajeEnvio
        const consultaSolicitud = await WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado)
  
        if (consultaSolicitud.payload.data.Pws_R_s_cod == "1") {
          const solicitud = consultaSolicitud.payload.data["datos_soli"]["datos_Sol"][0]
          console.log("2")
          const personaRequest = {
            Pws_ident_vende: solicitud["Pws_iden_vende"],
            Pws_nom_oficina: solicitud["Pws_nom_oficina"],
            Pws_num_solicitud: request.numSol!,
            Pws_Identificacion: solicitud["Pws_Identificacion"],
            Pws_Tip_Identificacion: solicitud["Pws_Tip_Identificacion"],
            Pws_Tip_Perfil: solicitud["Pws_Tip_Perfil"],
            Pws_linea_credito: solicitud["Pws_Linea_Credito"],
            Pws_Periodic: solicitud["Pws_Periodicidad"],
            Pws_Tip_person: solicitud["Pws_Tip_Person"],
            Pws_Fideliza: solicitud["Pws_Fideliza"],
            Pws_Tip_Estado: "14",
            Pws_fec_solicitud: solicitud["Pws_fec_solicitud"],
            Pws_Nombres: solicitud["Pws_Nombres"],
            Pws_Apellidos1: solicitud["Pws_Apellidos1"],
            Pws_Apellidos2: solicitud["Pws_Apellidos2"],
            Pws_Telefono1: solicitud["Pws_Telefono1"],
            Pws_Telefono2: solicitud["Pws_Telefono2"],
            Pws_Correo: solicitud["Pws_Correo"],
            Pws_Val_cuoini: solicitud["Pws_Val_cuoini"],
            Pws_Val_pla: solicitud["Pws_Val_pla"],
            Pws_Val_finan: solicitud["Pws_Val_finan"],
            Pws_Val_cuo: solicitud["Pws_Val_cuo"],
            Pws_Cod_prod: solicitud["Pws_Cod_prod"]
          }
          const actualizarEstado = await WLOCreditAPIService.addSolicitud(personaRequest)
          if (actualizarEstado.payload.result > 0) {
            const correo = {
              id: "",
              cupo: "0",
              number: request.numSol!,
              deseasMoto: true,
              email: sign.email!,
              typeIdent: sign.typeIdentification!,
              numbIdent: sign.numIdentification!,
              codeudorReq: true,
              Negado: false,
              sargilaft: false,
              enestudio: false
            }
            const response = await WLOCreditAPIService.getEmailCo(correo);
            MensajeEnvio = 'SE REQUIERE CODEUDORES'
            setTextoMensaje(MensajeEnvio);
            MostrarModal(true, Negado, sargilat, estudio, true, false)
          }
        }
      } else if (isChecked2 == true && DataCreditoCodeudor == false && (Negado == true || estudio == true)) {
        let estado
        let MensajeEnvio
        if (Negado == true) {
          estado = '5'
        }
        if (estudio == true) {
          estado = '12'
        }
        let valoragregado = {
          Pws_num_solicitud: request.numSol!,
          Pws_fec_solicitud_ini: "",
          Pws_fec_solicitud_fin: "",
          Pws_Tip_estado: "",
          Pws_Tip_Consulta: "1"
        }
        const consultaSolicitud = await WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado)
        if (consultaSolicitud.payload.data.Pws_R_s_cod == "1") {
          const MensajeEnvio1 = MensajeEnvio
          const solicitud = consultaSolicitud.payload.data["datos_soli"]["datos_Sol"][0]
          console.log("3")
          const personaRequest = {
            Pws_ident_vende: solicitud["Pws_iden_vende"],
            Pws_nom_oficina: solicitud["Pws_nom_oficina"],
            Pws_num_solicitud: request.numSol!,
            Pws_Identificacion: solicitud["Pws_Identificacion"],
            Pws_Tip_Identificacion: solicitud["Pws_Tip_Identificacion"],
            Pws_Tip_Perfil: solicitud["Pws_Tip_Perfil"],
            Pws_linea_credito: solicitud["Pws_Linea_Credito"],
            Pws_Periodic: solicitud["Pws_Periodicidad"],
            Pws_Tip_person: solicitud["Pws_Tip_Person"],
            Pws_Fideliza: solicitud["Pws_Fideliza"],
            Pws_Tip_Estado: estado,
            Pws_fec_solicitud: solicitud["Pws_fec_solicitud"],
            Pws_Nombres: solicitud["Pws_Nombres"],
            Pws_Apellidos1: solicitud["Pws_Apellidos1"],
            Pws_Apellidos2: solicitud["Pws_Apellidos2"],
            Pws_Telefono1: solicitud["Pws_Telefono1"],
            Pws_Telefono2: solicitud["Pws_Telefono2"],
            Pws_Correo: solicitud["Pws_Correo"],
            Pws_Val_cuoini: solicitud["Pws_Val_cuoini"],
            Pws_Val_pla: solicitud["Pws_Val_pla"],
            Pws_Val_finan: solicitud["Pws_Val_finan"],
            Pws_Val_cuo: solicitud["Pws_Val_cuo"],
            Pws_Cod_prod: solicitud["Pws_Cod_prod"]
          }
          const actualizarEstado = await WLOCreditAPIService.addSolicitud(personaRequest)
  
          if (actualizarEstado.payload.result > 0) {
            if (estado === '5') {
              MensajeEnvio = 'NEGADO'
            } else {
              MensajeEnvio = 'EN ESTUDIO'
            }
            const correo = {
              id: "",
              cupo: "0",
              number: request.numSol!,
              deseasMoto: true,
              email: sign.email!,
              typeIdent: sign.typeIdentification!,
              numbIdent: sign.numIdentification!,
              codeudorReq: false,
              Negado: Negado,
              sargilaft: sargilat,
              enestudio: estudio
            }
            const response = await WLOCreditAPIService.getEmail(correo);
            MostrarModal(true, Negado, sargilat, estudio, false, false)
            setTextoMensaje(MensajeEnvio);
          }
        }
      }
      if (isChecked1 == true) {
        valorfalse = isChecked1
        setvalorfalset(valorfalse)
  
        const cupo = String(localStorage.getItem('cupoRotativo'))
        setSolicutde(false)
  
        if (!DataCreditoCodeudor && !Negado && !sargilat && !estudio) {
          setButton(true);
          let MensajeEnvio
          const usuarios = JSON.parse(localStorage.getItem('solitudInfo')!);
          let lineas;
          let paylos, num = 0;
  
          let valoragregado = {
            Pws_num_solicitud: String(localStorage.getItem("num_solicitud")),
            Pws_fec_solicitud_ini: "",
            Pws_fec_solicitud_fin: "",
            Pws_Tip_estado: "",
            Pws_Tip_Consulta: "1"
          }
          let result;
          let fecha_inicia
          await WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado).then((data) => {
            fecha_inicia = data.payload.data.datos_soli.datos_Sol[0].Pws_fec_solicitud;
            result = data.payload.data.datos_soli.datos_Sol[0].Pws_Lincre
          });
          const valor = await AdminService.getlineascredito();
          lineas = valor.payload.data.ListLineaCredito.Datos_LineasCredito.length;
          let nume = 0
          let cupoRot;
          let codigo = 0
          while (nume < lineas) {
            if (valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_nombre == result) {
              cupoRot = valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_rotativo
              codigo = valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_codigo
              setCodigo(valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_codigo)
            }
            nume += 1
          }
          let exist = false
  
          if (cupoRot == 1) {
            let Mensaje: string
            let valoragregado = {
              Pws_num_solicitud: request.numSol!,
              Pws_fec_solicitud_ini: "",
              Pws_fec_solicitud_fin: "",
              Pws_Tip_estado: "",
              Pws_Tip_Consulta: "1"
            }
            const consultaSolicitud = await WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado)
            if (consultaSolicitud.payload.data.Pws_R_s_cod == "1") {
              const solicitud = consultaSolicitud.payload.data["datos_soli"]["datos_Sol"][0]
              let valorFinanciar = parseInt(solicitud["Pws_Val_finan"])
  
              let cupoAsignado = parseInt(cupo)
  
              if (valorFinanciar < cupoAsignado) {
                setTextoMensaje('APROBADO');
                MostrarModal(valorfalse, Negado, sargilat, estudio, DataCreditoCodeudor, true)
                const datas = {
                  cupo: String(cupo),
                  number: request.numSol!,
                  deseasMoto: !valorfalse,
                  email: sign.email!,
                  typeIdent: sign.typeIdentification!,
                  numbIdent: sign.numIdentification!,
                  codeudorReq: DataCreditoCodeudor,
                  Negado: Negado,
                  sargilaft: sargilat,
                  enestudio: estudio
                }
                const response = await WLOCreditAPIService.cupoEmail(datas);
                /////////////////
                //NR
                //Consumo graba score para generar la hoja de evaluacion pendiente por parametro tipodeudorcode
              /*   console.log("consumo graba score")
                const datosDeudor = JSON.parse(localStorage.getItem('solicitud')!);
                console.log(datosDeudor)
                const response1 = await WLOCreditAPIService.grabarScore({
                  ps_radicado: datosDeudor.radicado,
                  ps_identificacion: String(solicitud["Pws_Identificacion"]), //String(sign.numIdentification),
                  ps_cod_lin: solicitud["Pws_Linea_Credito"],
                  ps_tipoCliente: datosDeudor.tipocliente, 
                  ps_tip_deucode: datosDeudor.tipodeudor
                });
                console.log("respuesta: ", response1) */
                var d = new Date,
                  dformat = [d.getMonth() + 1,
                  d.getDate(),
                  d.getFullYear()].join('/');
                const creaCupo = {
                  s_r_ident_cliente: String(sign.numIdentification),
                  s_r_lin_cred: String(codigo),
                  s_r_fec_ini_cupo: String(dformat),
                  s_r_fec_fin_cupo: String(dformat),
                  s_r_monto_cupo: String(cupo)
                }
                if (!estudio && !Negado && !DataCreditoCodeudor) {
                  const responde = await WLOCreditAPIService.registraCupo(creaCupo)
                } else {
                  datas.cupo = ""
                }
                setButton(true);
              } else {
                console.log("4")
              
                const personaRequest = {
                  Pws_ident_vende: solicitud["Pws_iden_vende"],
                  Pws_nom_oficina: solicitud["Pws_nom_oficina"],
                  Pws_num_solicitud: request.numSol!,
                  Pws_Identificacion: solicitud["Pws_Identificacion"],
                  Pws_Tip_Identificacion: solicitud["Pws_Tip_Identificacion"],
                  Pws_Tip_Perfil: solicitud["Pws_Tip_Perfil"],
                  Pws_linea_credito: solicitud["Pws_Linea_Credito"],
                  Pws_Periodic: solicitud["Pws_Periodicidad"],
                  Pws_Tip_person: solicitud["Pws_Tip_Person"],
                  Pws_Fideliza: solicitud["Pws_Fideliza"],
                  Pws_Tip_Estado: "12",
                  Pws_fec_solicitud: solicitud["Pws_fec_solicitud"],
                  Pws_Nombres: solicitud["Pws_Nombres"],
                  Pws_Apellidos1: solicitud["Pws_Apellidos1"],
                  Pws_Apellidos2: solicitud["Pws_Apellidos2"],
                  Pws_Telefono1: solicitud["Pws_Telefono1"],
                  Pws_Telefono2: solicitud["Pws_Telefono2"],
                  Pws_Correo: solicitud["Pws_Correo"],
                  Pws_Val_cuoini: solicitud["Pws_Val_cuoini"],
                  Pws_Val_pla: solicitud["Pws_Val_pla"],
                  Pws_Val_finan: solicitud["Pws_Val_finan"],
                  Pws_Val_cuo: solicitud["Pws_Val_cuo"],
                  Pws_Cod_prod: solicitud["Pws_Cod_prod"],
                }
                const actualizarEstado = await WLOCreditAPIService.addSolicitud(personaRequest)
                if (actualizarEstado.payload.result > 0) {
                  const correo = {
                    id: "",
                    cupo: "0",
                    number: request.numSol!,
                    deseasMoto: false,
                    email: sign.email!,
                    typeIdent: sign.typeIdentification!,
                    numbIdent: sign.numIdentification!,
                    codeudorReq: false,
                    Negado: false,
                    sargilaft: false,
                    enestudio: true
                  }
                  const response = await WLOCreditAPIService.getEmail(correo);
                  setTextoMensaje('EN ESTUDIO');
                  MostrarModal(true, Negado, true, true, DataCreditoCodeudor, true);
                }
              }
            }
          } else {
            ////////
            setTextoMensaje('APROBADO');
          }
        } else {
          if (Number(request.valoresLogin.tipodeudor) < 2) {
            const datas = {
              id: "hola",
              number: request.numSol!,
              deseasMoto: !valorfalse,
              email: sign.email!,
              typeIdent: sign.typeIdentification!,
              numbIdent: sign.numIdentification!,
              codeudorReq: DataCreditoCodeudor,
              Negado: Negado,
              sargilaft: sargilat,
              enestudio: estudio,
              cupo: cupo
            }
            if (!estudio && !Negado && !DataCreditoCodeudor && valorfalse) {
              envioCupo();
            } else {
              datas.cupo = ""
            }
            const response = await WLOCreditAPIService.getEmail(datas);
            setButton(true)
          }
        }
        if (Negado && !DataCreditoCodeudor && !sargilat && !estudio) {
          MostrarModal(true, true, sargilat, estudio, DataCreditoCodeudor, true)
          setTextoMensaje('NEGADO');
        }
        if (DataCreditoCodeudor && !Negado && !sargilat && !estudio) {
          MostrarModal(true, Negado, sargilat, estudio, true, true)
          setTextoMensaje('CODEUDOR REQUERIDO');
        }
        if (!DataCreditoCodeudor && !Negado && sargilat && estudio) {
          MostrarModal(true, Negado, sargilat, estudio, true, true)
          setTextoMensaje('EN ESTUDIO');
        }
      }
    } else {
      toast.error('Se debe seleccionar algua de las dos opciones vistas');
    }
  }

  const modalMoto = () => {
    if (texto == 'NO') { valorChange(); }
    else {
      { setSolicutde(true); }
    }
  }

  const envioCupo = async () => {
    let lineas;
    const valor = await AdminService.getlineascredito();
    lineas = valor.payload.data.ListLineaCredito.Datos_LineasCredito.length;
    let nume = 0
    let cupoRot;
    let codigo = 0;
    let result;
    let fecha_inicia

    let valoragregado = {
      Pws_num_solicitud: String(localStorage.getItem("num_solicitud")),
      Pws_fec_solicitud_ini: "",
      Pws_fec_solicitud_fin: "",
      Pws_Tip_estado: "",
      Pws_Tip_Consulta: "1"
    }

    await WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado).then((data) => {
      fecha_inicia = data.payload.data.datos_soli.datos_Sol[0].Pws_fec_solicitud;
      result = data.payload.data.datos_soli.datos_Sol[0].Pws_Lincre
    });

    while (nume < lineas) {
      if (valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_nombre == result) {
        cupoRot = valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_rotativo
        codigo = valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_codigo
        setCodigo(valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_codigo)
      }
      nume += 1
    }
    const cupo = String(localStorage.getItem('cupoRotativo'))
    var d = new Date,
      dformat = [d.getMonth() + 1,
      d.getDate(),
      d.getFullYear()].join('/');
    const creaCupo = {
      s_r_ident_cliente: String(sign.numIdentification),
      s_r_lin_cred: String(codigo),
      s_r_fec_ini_cupo: String(dformat),
      s_r_fec_fin_cupo: String(dformat),
      s_r_monto_cupo: String(cupo)
    }
    const responde = await WLOCreditAPIService.registraCupo(creaCupo)
  }
  window.addEventListener('beforeunload', function (e) {
    var confirmationMessage = '¿Seguro que quieres finalizar proceso?';

    // Algunos navegadores necesitan que se asigne el valor al evento returnValue
    (e || window.event).returnValue = confirmationMessage; 
    return confirmationMessage; 
});

    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);

    const handleCheckbox1Change = (event: ChangeEvent<HTMLInputElement>) => {
      setIsChecked1(event.target.checked);
      if (event.target.checked) {
        setIsChecked2(false);
      }
      console.log(isChecked1);
    };

    const handleCheckbox2Change = (event: ChangeEvent<HTMLInputElement>) => {
      setIsChecked2(event.target.checked);
      if (event.target.checked) {
        setIsChecked1(false);
      }
      console.log(isChecked2);
    };

  return (
    <div className="mansaje">
      <ClockLoader id='spinner' color={"#d72222"} loading={isLoading} size={100} />

      {

     solicutde
          ?
          <>
            <div className="soliBody" onClick={() => {
              valorChange()
              setSolicutde(false)
            }}></div>
            <div className="solicutud">
              <p>¿La moto es para ti?</p>
              <div className="switch-tab">
              <div className="checkbox-wrapper">
             <input className="checkbox" type="checkbox" id="si" checked={isChecked1} onChange={handleCheckbox1Change}/>
             <label htmlFor="si">Si</label>
              </div>
              <div className="checkbox-wrapper">
                   <input className="checkbox" type="checkbox" id="no" name="respuesta" value="no" checked={isChecked2} onChange={handleCheckbox2Change}/>
            <label htmlFor="no">No</label>
              </div>
              </div> 
              <div>
                <Fab variant="extended" color="error" aria-label="add"
                  onClick={() => {
                  valorChange()
                  setSolicutde(false)
                  }}
                  disabled={!isChecked1 && !isChecked2}
                  style={{ 
                  opacity: (!isChecked1 && !isChecked2) ? 0.5 : 1,
                  cursor: (!isChecked1 && !isChecked2) ? 'not-allowed' : 'pointer'
                  }}
                  >
                  continuar
                  <ArrowForward sx={{ mr: 1 }} />
                </Fab>
              </div>
            </div>
          </>
         : <></>
      }
     
      { 
        MesanjeInfo
          ?
          <>
            <div className="soliBody" onClick={() => {
              setMensajeInfo(false)
            }}></div>
            <div className="solicutud">
              <p>{textoMensaje}</p>
            </div>

          </>
          : <></>
      }
      <MenuBar />
      <div className="mansajeBody">
        {/* <h4>cliente presenta riesgo: </h4> */}
        {/* <h1 >{texto}</h1> */}
      </div>

    </div>
  )
}
